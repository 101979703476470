import React, { useCallback, useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import './App.css';

function App() {
  const handle = useFullScreenHandle();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = useCallback((state, handle) => {
    console.log(state, handle);
    setIsFullScreen(state);
  }, [handle]);

  const touchHandler = (event) => {
    if (isFullScreen) {
      event.preventDefault();
      console.log("In fullscreen, ignoring touch event");
    }
  }

  return (
    <div className="App" onTouchStart={touchHandler} onTouchEnd={touchHandler} onTouchMove={touchHandler}>
      <FullScreen className='fullscreen-content' handle={handle} onChange={handleFullScreen}>
        <div className="header">{isFullScreen ? 'Fullscreen No Touch!' : 'Go fullscreen'}</div>
        <div className="logo">
          <img src="fullscreen-no-touch.svg" onClick={handle.enter} alt="logo" />
        </div>
        {isFullScreen && 
          <div className="body">
            You should now be in fullscreen mode and touch events should be ignored.
            <br/>
            <br/>
            (To escape you can pull down from top of screen)
          </div>
        }
      </FullScreen>
    </div>
  );
}

export default App;